import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import Devices from '../../pages/Devices';
import Events from '../../pages/Events';
import Geofence from '../../pages/Geofence';
import History from '../../pages/History';
import Subscriptions from '../../pages/Subscriptions';
import Settings from '../../pages/Settings';
import BikeRoutes from '../../pages/BikeRoutes';
import Statistics from '../../pages/Statistics';
import EventsAlco from "../../pages/EventsAlco";
import EventsAlcoNew from "../../pages/EventsAlcoNew";

import SidebarMobile from '../Sidebar/SidebarMobile';
import Sidebar from '../Sidebar/Sidebar';
import { useIsMobile } from '../../helpers/customHooks';
import { MapTogglesProvider } from '../Map/ToggleContext';
import { FLAVOR } from '../../helpers/constants';
import Map from '../Map/Map';
import { useContextData } from '../../components/ContextData';
import Login from '../../pages/Login';
import Signup from '../../pages/Signup';
import SignupInvite from '../../pages/SignupInvite';
import ForgotPassword from '../../pages/ForgotPassword';
import ResetPassword from '../../pages/ResetPassword';
import SubscriptionsInterim from '../../pages/SubscriptionsInterim';
import FirebaseModal from '../Modals/FirebaseModal';
import { requestForToken, onMessageListener } from '../../helpers/firebaseConfig';
import toast from "react-hot-toast";

const Routing = (props) => {
	const MOBILE = useIsMobile();
    const location = useLocation();
    const { accessToken } = useContextData();
// 	const [notificationPermission, setNotificationPermission] = useState(Notification.permission);
// 	const [showModal, setShowModal] = useState(Notification.permission !== 'granted')
// 	const [queuedNotification, setQueuedNotification] = useState(null);

// 	const handleAccept = () => {
// 		Notification.requestPermission()
// 		  .then((permission) => {
// 			setNotificationPermission(permission);
// 			console.log(permission);
			
// 			if (permission === 'granted') {
// 			  // Request the FCM token after permission is granted
// 			  requestForToken();
// 			} else {
// 			  console.error('Notification permission not granted.');
// 			}
// 		  })
// 		  .catch((error) => console.error('Error requesting notification permission:', error));
	
// 		setShowModal(false); // Close the modal
// 	  };
	
// 	  // Function to handle "Deny" button click
// 	  const handleDeny = () => {
// 		console.log('Notification permission denied.');
// 		setShowModal(false); // Close the modal
// 	  };

// 	  useEffect(() => {
// 		// Check if the permission is already granted
// 		if (notificationPermission === 'granted') {
// 		  requestForToken();
// 		}
	
// 		console.log("Setting up message listener");
	
// 		// Set up foreground message listener using a callback
// 		onMessageListener((payload) => {
// 		  console.log('Message received in the foreground: ', payload);
// 		  // Use toast to show notification
// 		  // Play the sound and show toast directly if in the foreground
// 		  triggerToastAndSound({
// 			title: payload.data.title,
// 			body: payload.data.body,
// 			sound: payload.data.sound || 'default_sound.wav',
// 		  });
// 		});
// 	  }, [notificationPermission]);

//   // Handle messages coming from the service worker (background)
//   useEffect(() => {
//     if ('serviceWorker' in navigator) {
//       const handleServiceWorkerMessage = (event) => {
// 		console.log(event.data);
		
//         if (event.data && (event.data.type === 'BACKGROUND_MESSAGE' || event.data.type === "PLAY_SOUND_ON_INTERACTION")) {
//           const notification = {
//             title: event.data.title || 'New Notification',
//             body: event.data.body || 'You have a new message.',
//             sound: event.data.sound || 'default_sound.wav',
//           };
  
//           // If the app is in the background, queue the notification
//           if (document.hidden) {			
//             console.log('App is in background, queuing notification.');
//             setQueuedNotification(notification);
//           } else {
//             // App is in the foreground, trigger notification immediately
//             triggerToastAndSound(notification);
//           }
//         }
//       };

//       // Listen for messages from the service worker
//       navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessage);

//       return () => {
//         // Cleanup to avoid multiple listeners
//         navigator.serviceWorker.removeEventListener('message', handleServiceWorkerMessage);
//       };
//     }
//   }, []);

//   // Handle when the app comes back into focus after being in the background
//   useEffect(() => {
//     const handleVisibilityChange = () => {
//       if (!document.hidden && queuedNotification) {
//         // App is now in focus, trigger the queued notification
//         console.log('App is now in focus, triggering queued notification.');
//         triggerToastAndSound(queuedNotification);
//         setQueuedNotification(null); // Clear the queue
//       }
//     };

//     // Listen for visibility changes
//     document.addEventListener('visibilitychange', handleVisibilityChange);

//     return () => {
//       // Cleanup the event listener
//       document.removeEventListener('visibilitychange', handleVisibilityChange);
//     };
//   }, [queuedNotification]);

//   const triggerToastAndSound = (notification) => {
//     const soundPath = `/notifications/${notification.sound}`;
//     console.log(`Playing sound from: ${soundPath}`);

//     const audio = new Audio(soundPath);
//     audio.play().catch(error => {
//       console.error('Error playing notification sound:', error);
//     });

//     toast.success(`${notification.title}: ${notification.body}`);
//   };

    const trackingRoutes = () => {
		
		return(
		<Routes>
			<Route path={"/devices"} element={<Devices />} />
			<Route path={"/events"} element={<Events />} />
			<Route path={"/geofence"} element={<Geofence />} />
			<Route path={"/history"} element={<History />} />
			<Route path={"/subscriptions"} element={<SubscriptionsInterim />} /> {/* OLD DATAPLAN */}
			{/* <Route path={"/subscriptions"} element={<Subscriptions />} /> NEW DATAPLAN */}
			<Route path={"/settings"} element={<Settings />} />
			<Route path="*" element={<Navigate to="/devices" replace />} />
		</Routes>
		)
	}
	const bikeRoutes = () => {
		return(
		<Routes>
			<Route path={"/devices"} element={<Devices />} />
			<Route path={"/events"} element={<Events />} />
			<Route path={"/geofence"} element={<Geofence />} />
			<Route path={"/history"} element={process.env.REACT_APP_ASSETS === "rawbike" || process.env.REACT_APP_ASSETS === "livelo" ? <History /> : <BikeRoutes />} />
			{process.env.REACT_APP_ASSETS !== "rawbike" && <Route path={"/statistics"} element={<Statistics />} />}
			{/* <Route path={"/subscriptions"} element={<Subscriptions />} /> */}
			<Route path={"/subscriptions"} element={<SubscriptionsInterim />} /> {/* OLD DATAPLAN */}
			<Route path={"/settings"} element={<Settings />} />
			<Route path="*" element={<Navigate to="/devices" replace />} />
		</Routes>
		)
	}
	const alcoRoutes = () => {
		return(
		<Routes>
			<Route path={"/devices"} element={<Devices />} />
			<Route path={"/events"} element={<EventsAlcoNew />} />
			<Route path={"/settings"} element={<Settings />} />
			<Route path="*" element={<Navigate to="/devices" replace />} />
		</Routes>
		)
	}
	const loggedOutRoutes = () => {		
		return(
		<Routes>
			<Route path={"/login"} element={<Login />} />
			<Route path={"/signup"} element={<Signup />} />
			<Route path={"/signup/:token"} element={<SignupInvite />} />
			<Route path={"/forgot"} element={<ForgotPassword />} />
			<Route path={"/reset/:token"} element={<ResetPassword />} />
			<Route path="*" element={<Navigate to="/login" replace />} />
		</Routes>
		)
	}
	
    return(
		<>
        {accessToken 
        ?
        <>
        {MOBILE ? <SidebarMobile /> : <Sidebar />}
		<div className={`d-flex flex-row section-data`}>
			<MapTogglesProvider>
				{FLAVOR === "tracking"
					? trackingRoutes()
					: FLAVOR === "bike"
					? bikeRoutes()
					: FLAVOR === "alco"
					? alcoRoutes()
					: null
				}
                {(location.pathname !== "/settings" && location.pathname !== "/subscriptions" && location.pathname !== "/statistics") && 
                    <Map/>		
                }
			</MapTogglesProvider>
			{/* <FirebaseModal show={showModal} onAccept={handleAccept} onDeny={handleDeny} /> */}
		</div>
		</>
        :
		loggedOutRoutes()
        }
</>
		)
}

export default Routing;