import React, { useState, useEffect } from 'react';

import { Dialog, Card } from '@mui/material';
import { images, icons, STRIPE_TEST_KEY } from "../../helpers/constants";
import { useTranslation } from "react-i18next";
import Button from '../Buttons/Button';
import { useIsMobile } from '../../helpers/customHooks';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs'; // Import dayjs
import 'dayjs/locale/sv';
import { fixDate } from '../../helpers/helperFunctions';

const OverrideModal = (props) => {
    const MOBILE = useIsMobile();
    const { t } = useTranslation();
    const [show, setShow] = useState(props.show ?? false)
    const [errorText, setErrorText] = useState("")
    const [date, setDate] = useState(dayjs())


    useEffect(() => {
        setShow(props.show ?? false)
    },[props.show])


    
    const handleClose = () => {
        props.onClose()
      //  setShow(false)
    }

    const handleSave = () => {
        props.save(date.toJSON())        
    }



    return(
        <>
        <Dialog fullWidth={MOBILE ? true : false} open={show} onClose={handleClose}>
            <Card className="settings-dialog d-flex flex-column">
                <div className="w-100 h-100">
                    <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                        <div className='d-flex align-items-center'>
                            <img src={icons.user_settings} alt=""/>
                            <h3 className="ms-2">{t("devices.override")}</h3>
                        </div>
                        <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
                    </div>
                    <div className="p-3">
                        <p className="thin dark-grey text-center pb-3">{t("devices.override_sub")}</p>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='sv'>
                            <DatePicker
                                ampm={false}
                                value={date}
                                onChange={(newValue) => setDate(newValue)}
                                minDate={dayjs()}
                                className="date_label w-100"
                            />
                        </LocalizationProvider>
                    </div>
                    <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
                        <Button onClick={() => props.onClose()} btnClass="btn-secondary me-2">{t("settings.cancel")}</Button>
                        <Button onClick={() => handleSave(props.type)} btnClass="btn-primary">{t("settings.save")}</Button>
                    </div>
                </div>
            </Card>
        </Dialog>
        </>
    )
}

export default OverrideModal;