import React, { useState, useEffect } from 'react';
import { INDEX_URL, CUST, icons } from "../helpers/constants";
import { useLocation } from 'react-router-dom';
import * as request from "../services/FetchService";
import Button from '../components/Buttons/Button';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs'; // Import dayjs
import 'dayjs/locale/sv';
import DeviceSelectionRow from '../components/Devices/DeviceSelectionRow';
import { useTranslation } from "react-i18next";
import Searchbar from '../components/Searchbar/Searchbar';
import { useContextData } from '../components/ContextData';
import { useMapToggles } from '../components/Map/ToggleContext'; // Adjust the path as necessary
import { fixDate, fixDateEvent, staticSort } from '../helpers/helperFunctions';
import DragComponent from '../components/DragComponent';
import toast from 'react-hot-toast';

const History = (props) => {
	const { t } = useTranslation();
	const { deviceListCompact } = useContextData();
	const [startDate, setStartDate] = useState(dayjs().subtract(1, 'day'))
	const [endDate, setEndDate] = useState(dayjs())
	const [deviceList, setDeviceList] = useState([])
	const [deviceListFull, setDeviceListFull] = useState([])
	const [currentFilter, setCurrentFilter] = useState(null)
	const { historyData, setHistoryData } = useMapToggles();
	const [loading, setLoading] = useState(true)
	const [toggleDrag, setToggleDrag] = useState(false)

	const location = useLocation();

	useEffect(() => {
		if(location.state && location.state.history){
			let history = location.state.history
			setHistoryData(history[0])
		}

		var script = document.querySelector("#exceljsscript");
		if (!script) {
			const script2 = document.createElement("script");
			script2.id = "exceljsscript";
			script2.src = "https://cdn.jsdelivr.net/npm/exceljs@4.3.0/dist/exceljs.min.js";
			script2.async = true;
			document.body.appendChild(script2);
		}

		getDevices()
	},[])

	const getDevices = () => {
		if(deviceListCompact?.length>0){
			setDeviceListFull(deviceListCompact)
			setLoading(false)
		}else{
			request.get("devices/compactinfo").then((response) => {
				let sorted = staticSort(response.data.devices, "name")
				setDeviceList(sorted);
				setDeviceListFull(sorted);
				setLoading(false)
			});
		}
	};


	useEffect(() => {
		let sorted = staticSort(deviceListFull, "name")
		setDeviceList(sorted);
	}, [deviceListFull]);


	const selectDevice = (index) => {
		let updatedDevices = deviceList.map((device, idx) => {
			return {
				...device,
				// Set the device's selected property to true if its index matches the selected index
				// Otherwise, set it to false
				selected: idx === index ? !device.selected : false
			};
		});
		setDeviceList(updatedDevices);
	}

	const filterSearch = (searchWord) => {
		let array;
		let match;

		if(!searchWord || searchWord === ""){
			setDeviceList(deviceListFull)
			return
		}
		array = deviceListFull;
		match = array?.filter((device) => {
		const item = searchWord.toLowerCase();
		return (
			device.name.toLowerCase().indexOf(item) > -1 ||
			device.uniqueid.toLowerCase().indexOf(item) > -1
			);
		})
		setDeviceList(match)
	}

	const getHistory = () => {
		let id = deviceList.find(device => device.selected).id
		let obj = {
			deviceid: id,
			starttime: startDate.toDate(),
			stoptime: endDate.toDate()
		}		
        request.post("", "history/list", obj).then((response) => {
			if(response.data.errorCode){
				toast.error(response.data.message)
				// alert(response.data.message)
			}else{
				toast.success(t("toast.success"))
				setHistoryData(response.data[0])
				setToggleDrag(true)
			}
		});
	}

	const calculateTimeActive = (totalSeconds) => {
		const days = Math.floor(totalSeconds / (24 * 3600));
		totalSeconds %= 24 * 3600;
		const hours = Math.floor(totalSeconds / 3600);
		totalSeconds %= 3600;
		const minutes = Math.floor(totalSeconds / 60);
		const seconds = totalSeconds % 60;
	  
		let parts = [];
		if (days > 0) parts.push(`${days} ${t("history.days", {count : days})}`);
		if (hours > 0) parts.push(`${hours} ${t("history.hours", {count : hours})}`);
		if (minutes > 0) parts.push(`${minutes} ${t("history.minutes", {count : minutes})}`);
		if (seconds > 0) parts.push(`${seconds} ${t("history.seconds", {count : seconds})}`);
	  
		return parts.join(' ');
	}

	const calculateTotalDistance = (totalDistance) => {
		if(totalDistance < 1000){
			return (<>{totalDistance.toFixed(1)} {t("history.meters")}</>)
		}else{
			return (<>{(totalDistance/1000).toFixed(1)} {t("history.kilometers")}</>)
		}
	}

	const calculateTotalDistanceReport = (totalDistance) => {
		if(totalDistance < 1000){
			return `${totalDistance.toFixed(1)} ${t("history.meters")}`
		}else{
			return `${(totalDistance/1000).toFixed(1)} ${t("history.kilometers")}`
		}
	}


	const downloadData = () => {
		const workbook = new window.ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet("List");
		const device = historyData?.devicename
		const startDate = historyData?.starttime
		const endDate = historyData?.stoptime
		
		worksheet.addRow([`${device.charAt(0).toUpperCase() + device.slice(1)}`, "", `${fixDate(startDate)} - ${fixDate(endDate)}`, "", "", "", "", "", `${calculateTimeActive(historyData.totalactivetime)}`, "", "", `${calculateTotalDistanceReport(historyData.totaldistance)}`, "", ""]);
		worksheet.mergeCells('A1:B1');
		worksheet.mergeCells('C1:H1');
		worksheet.mergeCells('I1:K1');
		worksheet.mergeCells('L1:N1');
		// Add a blank row for headers
		worksheet.addRow([]);

		worksheet.getRow(1).font = { bold: true };
		worksheet.getRow(1).height = 20;
		worksheet.getRow(1).alignment = { vertical: "middle", horizontal: "center" };
		worksheet.eachRow((row, rowNumber) => {
			if(rowNumber !== 1){
				row.height = 15;
			}
		});
		worksheet.getRow(2).font = { bold: true };
		worksheet.getRow(2).height = 20;
		worksheet.getRow(2).alignment = { vertical: "middle", horizontal: "center" };
		worksheet.getRow(2).values = [t("history.date"), t("history.latitude"), t("history.longitude")];
		worksheet.columns = [
			{ key: "date", width: 20 },
			{ key: "lat", width: 20 },
			{ key: "lng", width: 20 }
			// Further configuration...
		];
		historyData?.points.map((data, index) => {
			worksheet.addRow({ 
				date: (fixDate(data.datetime)), 
				lat: (data.latitude), 
				lng: (data.longitude)
			})
		})

		workbook.xlsx.writeBuffer().then(function (buffer) {
			const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
			const url = window.URL.createObjectURL(blob);
			// Create a download link and simulate a click on it
			const a = document.createElement("a");
			a.href = url;
			a.download = `location_history_${fixDate(startDate, true)}-${fixDate(endDate, true)}`;
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(url);
		});
	};

	const renderSearch = () => {
		return (
			<>
				<div className="d-flex flex-column my-3">
					{deviceList && deviceList.length > 0 ?
						<>
						<h3 className="">{t("history.choose_time")}</h3>
						<div className="d-flex mt-md-3">
							<div className="d-flex col-lg-6 col-sm-6 px-0 flex-column">
								<h4 className="">{t("history.start")}</h4>
								<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='sv'>
									<DateTimePicker
										ampm={false}
										value={startDate}
										onChange={(newValue) => setStartDate(newValue)}
										maxDate={endDate}
										className="date_label me-2"
									/>
								</LocalizationProvider>
							</div>
							<div className="d-flex col-lg-6 col-sm-6 px-0 flex-column">
								<h4 className="ms-2">{t("history.end")}</h4>
								<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='sv'>
									<DateTimePicker
									ampm={false}
									value={endDate}
									onChange={(newValue) => setEndDate(newValue)}
									minDate={startDate}
									maxDate={dayjs()}
									className="date_label ms-2"
									/>
								</LocalizationProvider>
							</div>
						</div>
						<div className="d-flex mt-3 flex-column">

							<h4 className="ms-0 mb-2">{t("history.choose_device")}</h4>
							<Searchbar className="mb-3" submitSearch={(data) => filterSearch(data)} />
							<div className={`history_device_list scrollable-y shadow-small ${deviceList.some(device => device.selected) ? "pushed" : ""}`}>
							{deviceList.map((device, index) => (
								<DeviceSelectionRow
									key={index}
									text={device.name}
									index={index}
									selected={device.selected ? true : false}
									onClick={() => selectDevice(index)}
									/>
								))}
							</div>
							
						</div>
						<div>
							<Button onClick={() => getHistory()} btnClass={`btn-primary float-end mt-3 ${!deviceList.some(device => device.selected) && "d-none"}`}>{t("history.get_history")}</Button>
						</div>
						</>
						: !loading
						?
						<h3 className="bold dark-grey text-center mt-5">{t("history.no_devices")}</h3>
						: null
					}
					
				</div>
			</>
		)
	}


	const renderHistory = () => {
		return (
			<>
			<Button onClick={() => setHistoryData()} icon={icons.back} className="mt-3" btnClass="btn-left-section">
				{t("history.back_to_search")}
			</Button>
			<div className="card mt-3 p-4 d-flex flex-column justify-content-between border-12 border-0 shadow-small">
				<h3 className="">{t("history.history_information")}</h3>
				<hr/>
				<h4>{historyData.devicename}</h4>
				<div className="d-flex mt-3">
					<img className="align-self-center me-2" src={icons.bell_active} alt=""/>
					<p className="bold align-self-center">{fixDate(historyData.starttime)} - {fixDate(historyData.stoptime)}</p>
				</div>
				<div className="d-flex mt-3">
					<img className="align-self-center me-2" src={icons.bell_active} alt=""/>
					<p className="bold align-self-center">{t("history.time_active")}: {calculateTimeActive(historyData.totalactivetime)}</p>
				</div>
				<div className="d-flex mt-3">
					<img className="align-self-center me-2" src={icons.bell_active} alt=""/>
					<p className="bold align-self-center">{t("history.total_distance")}: {calculateTotalDistance(historyData.totaldistance)}</p>
				</div>
				<Button onClick={() => downloadData()} lightIcon={true} icon={icons.download} className="mt-3 align-self-end" btnClass="btn-primary">
					{t("history.download_report")}
				</Button>
			</div>
			</>
		)
	}
	
	return(
		<>
		<div className="section-left_bottom_data" id="resizable-element">
			<DragComponent dragId="resizable-element" initialH="60px" maxH={80} minH="60px" close={toggleDrag} setClose={() => setToggleDrag(false)}/>
			<div className="d-flex flex-row mt-4 align-items-center page_header">
				<img src={icons.sidebar.history_active} className="title_icon primary-icon" alt="..." />
				<h1 className="h1 primary ms-3">{t("history.location_history")}</h1>
			</div>
			{historyData 
				? renderHistory()
				: renderSearch()
			}
		</div>	
		</>
	)
}

export default History;